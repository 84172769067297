import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './ManualLossComponent.metadata.json5';

function ManualLossComponent(props) {
    const {
        lossVM,
        isReadOnly,
        onValueChange,
        hideCategory,
        isMultiLine,
        policyLinePatterns
    } = props;

    const [policyLineOption, setPolicyLineOption] = useState([]);

    useEffect(() => {
        if (lossVM.value) {
            let policyLineList = lossVM.value.policyLineOptions || policyLinePatterns || []
            policyLineList = policyLineList.filter(f => f.checked).map(d => ({code: d.publicId, name: d.name}))
            setPolicyLineOption(policyLineList)
        }
    }, [lossVM, policyLinePatterns])

    const overrideProps = {
        '@field': {
            labelPosition: "left",
            readOnly: isReadOnly
        },
        category: {
            visible: !hideCategory
        },
        policyLine: {
            visible: !!isMultiLine,
            availableValues: policyLineOption,
        }
    }


    const readValue = useCallback((fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, lossVM, fieldId, fieldPath, overrideProps
        );
    }, [lossVM]);
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={lossVM}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            resolveValue={readValue}
        />
    );
}
ManualLossComponent.propTypes = {
    isReadOnly: PropTypes.bool,
    hideCategory: PropTypes.bool
};

ManualLossComponent.defaultProps = {
    isReadOnly: false,
    hideCategory: false
};

export default ManualLossComponent;
