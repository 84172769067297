import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { QuoteUtil } from 'wni-portals-util-js';
import { CheckboxField, Link, useModal } from '@jutro/components';
import { GLSublineService } from 'wni-capability-quoteandbind-gl';
import WizardPage from '../../templates/GLWizardPage';
import metadata from './GLSublineSelectionPage.metadata.json5';
import messages from './GLSublineSelectionPage.messages';
import styles from './GLSublineSelectionPage.module.scss';

const COVAEABLES_PATH = 'lobData.generalLiability.coverables';
const PATH = `${COVAEABLES_PATH}.subLines`;
const stateSimpleInfosPATH = 'lobData.generalLiability.stateSimpleInfos';
const SELECTED_SUBLINE_PATH = `${COVAEABLES_PATH}.sublineSelected`;
const LOCATIONS_PATH = `${COVAEABLES_PATH}.locations`;

function GLSublineSelectionPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardSnapshot,
        updateWizardData,
        readOnly,
    } = props;

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value;

    const translator = useTranslator();
    const modalApi = useModal();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const { loadingMask: { setLoadingMask }, domainCompany } = useDependencies(['loadingMask', 'domainCompany']);
    const { initialValidation, onValidate, isComponentValid } = useValidation('GLSublineSelectionPage');
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const [allErrors, updateAllErrors] = useState({
        premisesSublineError: false,
        ownersSublineError: false,
        railroadSublineError: false
    });

    const subLines = _.get(submissionVM.value, PATH);
    const [tableData, updateTableData] = useState(subLines);

    const noneSelectedState = 'None Selected';
    const premiseSublineText = 'Premises/Operations and Products/Completed Operations';
    const liquorSublineText = 'Liquor';
    const proWithdrawalSublineText = 'Product Withdrawal';
    const ownersSublineText = 'Owners and Contractors';
    const railroadSublineText = 'Railroad';

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    const hideOrShowLiquorAndProWithdrawal = useCallback((newestSublines = tableData) => {
        const premisesSubline = _.find(newestSublines, subline => {
            return subline.subline === premiseSublineText;
        })
        const liquorSubline = {
            subline: liquorSublineText,
            selected: false,
            formattedJurisdictions: noneSelectedState
        }
        const proWithdrawalSubline = {
            subline: proWithdrawalSublineText,
            selected: false,
            formattedJurisdictions: noneSelectedState
        }
        let newTableData = _.cloneDeep(newestSublines);
        let allSublines
        if (premisesSubline.selected) {
            if (!_.find(newTableData, item => item.subline === liquorSublineText)) {
                newTableData.splice(1, 0, liquorSubline);
            }
            if (!_.find(newTableData, item => item.subline === proWithdrawalSublineText)) {
                newTableData.splice(3, 0, proWithdrawalSubline);
            }
            allSublines = newTableData
            _.set(allErrors, 'premisesSublineError', false);
            updateAllErrors(allErrors);
        } else {
            newTableData = _.map(newestSublines, (subline) => {
                if (subline.subline === liquorSublineText || subline.subline === proWithdrawalSublineText) {
                    _.set(subline, 'selected', false);
                    _.set(subline, 'formattedJurisdictions', noneSelectedState);
                }
                return subline;
            })

            allSublines = _.cloneDeep(newTableData);
            _.remove(newTableData, (subline) => {
                return subline.subline === liquorSublineText || subline.subline === proWithdrawalSublineText
            })
            _.set(allErrors, 'premisesSublineError', true)
            updateAllErrors(allErrors);
        }
        updateTableData(newTableData);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, PATH, allSublines);
        updateWizardData(newSubmissionVM);
    }, [tableData])

    const showOwnersOrRailroadError = (newTableData) => {
        const selectedSublines = _.filter(newTableData, (item) => {
            return item.selected
        })
        const isOwnersSelected = _.filter(selectedSublines, (item) => {
            return item.subline === ownersSublineText
        })
        const isRailroadSelected = _.filter(selectedSublines, (item) => {
            return item.subline === railroadSublineText
        })
        if (!_.isEmpty(isOwnersSelected) && selectedSublines.length > 1) {
            _.set(allErrors, 'ownersSublineError', true);
        } else {
            _.set(allErrors, 'ownersSublineError', false);
        }
        if (!_.isEmpty(isRailroadSelected) && selectedSublines.length > 1) {
            _.set(allErrors, 'railroadSublineError', true);
        } else {
            _.set(allErrors, 'railroadSublineError', false);
        }
        updateAllErrors(allErrors);
    }

    const onSelectionChange = (val, path, item) => {
        const newValue = {
            ...item,
            [path]: val
        };
        if (val) {
            _.set(newValue, 'formattedJurisdictions', 'AK');
        } else {
            _.set(newValue, 'formattedJurisdictions', noneSelectedState);
        }
        const newTableData = _.cloneDeep(tableData);
        const currentIndex = tableData.findIndex((v) => v.subline === item.subline);
        _.set(newTableData, currentIndex, newValue);
        updateTableData(newTableData);
        hideOrShowLiquorAndProWithdrawal(newTableData);
        showOwnersOrRailroadError(newTableData);
    };

    useEffect(() => {
        const premisesSubline = _.find(tableData, subline => {
            return subline.subline === premiseSublineText;
        })
        hideOrShowLiquorAndProWithdrawal();
        // Premises/Operations... will always be selected as a default For UIC
        const hasSelectedSubline = _.find(tableData, subline => {
            return subline.selected
        })
        if (_.get(domainCompany, 'code') === 'UIC' && _.isEmpty(hasSelectedSubline)) {
            onSelectionChange(true, 'selected', premisesSubline);
        }
    }, []);

    const renderCheckbox = (item, index, { path}) => {
        return <CheckboxField value={item[path]} readOnly={readOnly} onValueChange={(value) => onSelectionChange(value, path, item)} />
    }

    const handleValidation = () => {
        updateShowErrors(true);
    };

    const onPageNext = async () => {
        if (!isComponentValid) {
            handleValidation();
            return false;
        }
        if (readOnly) {
            return submissionVM;
        }
        updateServiceCallInProgress(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM.value, PATH, [])
        }
        const res = await GLSublineService.updateSublineSelected(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, PATH, res.subLines);
        _.set(newSubmissionVM.value, SELECTED_SUBLINE_PATH, res.sublineSelected);
        _.set(newSubmissionVM.value, stateSimpleInfosPATH, res.stateSimpleInfos);
        _.set(newSubmissionVM.value, LOCATIONS_PATH, res.gllocations)
        updateWizardSnapshot(newSubmissionVM);
        updateServiceCallInProgress(false);
        return newSubmissionVM;
    };

    const renderSublineCell = (rowData, index, property) => {
        const selectedSublines = _.filter(tableData, (item) => {
            return item.selected
        })
        const isOwnersSelected = _.filter(selectedSublines, (item) => {
            return item.subline === ownersSublineText
        })
        const isRailroadSelected = _.filter(selectedSublines, (item) => {
            return item.subline === railroadSublineText
        })
        let sublineCell
        if (!_.isEmpty(isOwnersSelected) && selectedSublines.length > 1 && rowData[property.path] === ownersSublineText) {
            sublineCell = (
                <div>
                    <div>{rowData[property.path]}</div>
                    <div className='font-error'>{translator(messages.ownersSublineErrorMessage)}</div>
                </div>
            )
        } else if (!_.isEmpty(isRailroadSelected) && selectedSublines.length > 1 && rowData[property.path] === railroadSublineText) {
            sublineCell = (
                <div>
                    <div>{rowData[property.path]}</div>
                    <div className='font-error'>{translator(messages.railroadSublineErrorMessage)}</div>
                </div>
            )
        } else {
            sublineCell = rowData[property.path]
        }

        return sublineCell;
    }

    const selectOtherStates = (event, rowData) => {
        event.preventDefault();
        modalApi.showAlert({
            title: 'select different states',
            message: 'requirements to come with later state release...',
            status: 'info',
            icon: 'mi-info-outline'
        }).catch(_.noop);
    }

    const renderStateCell = (rowData, index, property) => {
        if (_.get(domainCompany, 'code') === 'UIC' || !rowData.selected) {
            return rowData.formattedJurisdictions
        }
        return (
            <Link onClick={(event) => selectOtherStates(event, rowData)} href="/">{rowData.formattedJurisdictions}</Link>)
    }

    const overrideProps = {
        '@field': {
            readOnly
        },
        wniHeaderSection: {
            errorState: _.includes(allErrors, true)
        },
        sublineErrorMessages: {
            visible: _.get(allErrors, 'premisesSublineError')
        },
        sublineTable: {
            data: tableData
        },
        sublineLine: {
            renderCell: renderSublineCell
        },
        state: {
            renderCell: renderStateCell
        }
    };

    const resolvers = {
        callbackMap: {
            renderCheckbox
        },
        componentMap: {
        },
        classNameMap: styles
    };
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                showErrors={showErrors}
                onValidationChange={onValidate}
                {...resolvers}
            />
        </WizardPage>
    );
}

export default GLSublineSelectionPage;
