import _ from 'lodash';
import {React, useState, useCallback, useContext, useEffect} from 'react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CPRisksService, CPRetrieveRiskItemSummaryService } from 'wni-capability-quoteandbind-cp';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { Button } from '@jutro/components'
import { Flex } from '@jutro/layout'
import { useTranslator } from '@jutro/locale';
import { useWniModal, ValidationIssuesComponent } from 'wni-components-platform-react';
import { ValidationIssueUtil } from 'wni-portals-util-js';
import { AddContactPopup, CoverageContactSearchPopup} from 'wni-capability-common-react';
import BuildingClauses from '../BuildingClauses';
import metadata from '../Common/CommonRiskItemDetails.metadata.json5';
import DetailsFieldMap from '../Common/DetailsFieldMap';
import AdditionalBuildingDetails from '../AdditionalBuildingDetails/AdditionalBuildingDetails';
import SearchClassCodeDescriptionPopup from '../Common/SearchClassCodeDescriptionPopup';
import messages from '../RiskItemComponent/RiskItemComponent.messages'

const dtoName = 'wni.edge.capabilities.quote.lob.commercialproperty.dto.CPBuildingDTO';
const ADD_TO_REQUIRED_LIST = ['Occupancy Description', 'Construction Code', 'Building Code Effectiveness Grade Class', 'Coverage']

const Building = (props) => {
    const modalApi = useWniModal();
    const {
        onValidate,
        isComponentValid,
    } = useValidation('SpecialClass');

    const { authHeader } = useAuthentication();
    const [buildingClauses, setBuildingClauses] = useState()
    const [showErrors, updateShowErrors] = useState(false)
    const [validationIssues, updateValidationIssues] = useState([]);

    const {
        riskItem,
        jobID,
        sessionUUID,
        submissionVM,
        updateSubmissionVM,
        isReadOnly,
        handleCloseRisk,
    } = props

    const {
        locationPublicID,
        publicID: buildingPublicID,
        locationDescription
    } = riskItem

    const serviceProps = {
        jobID,
        sessionUUID,
        authHeader
    }
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
   
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const [currentRowVM, setCurrentRowVM] = useState({})
    const [detailsVM, updateDetailsVM] = useState({})
    const [additionalDetailsVM, updateAdditionalDetailsVM] = useState({})

    const {
        value: {
            mortgageeDetails,
        } = {}
    } = detailsVM
    const fieldModels = _.get(detailsVM?.value, 'buildingDisplayables', []);
 
    const showSearchModal = useCallback((tableData, ratingTypeValue, isClassDescriptionSearch) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            tableData,
            oldRatingType: ratingTypeValue,
            isClassDescriptionSearch
        };
        return modalApi.showModal(
            <SearchClassCodeDescriptionPopup {...componentProps} />
        );
    }, [modalApi]);

    const fireProtectionSystemDataVM = _.get(additionalDetailsVM, 'additionalDetails.fireProtectionSystemData')

    const updateMortGageeDetails = (newMortGageeDetails) => {
        const newDetailsVM = _.clone(detailsVM)
        _.set(detailsVM.value, 'mortgageeDetails', newMortGageeDetails)
        updateDetailsVM(newDetailsVM)
    }

    const splitBuildingDetailsDTO = (res) => {
        const filteredDetailsDisplayableDTOList = res?.buildingDisplayables?.filter((elt) => elt.required || ADD_TO_REQUIRED_LIST.includes(elt.label))
        const filteredAdditionalDetailsDisplayableDTOList = res?.buildingDisplayables?.filter((elt) => !elt.required && !ADD_TO_REQUIRED_LIST.includes(elt.label))
        const filteredDetailsBuildingDTO = _.set(_.clone(res), 'buildingDisplayables', filteredDetailsDisplayableDTOList)
        const filteredAdditionalDetailsBuildingDTO = _.set(_.clone(res), 'buildingDisplayables', filteredAdditionalDetailsDisplayableDTOList)
        return [filteredDetailsBuildingDTO, filteredAdditionalDetailsBuildingDTO ]
    }

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true)
        const res = await serviceCallFunc()
        setLoadingMask(false)
        return res
    }

    const getBuildingDetailsFunc = useCallback(async () => {

        const res = await CPRisksService.getBuildingDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, true, authHeader);
        const splitedDTO = splitBuildingDetailsDTO(res)
        return splitedDTO
    }, [authHeader, buildingPublicID, jobID, locationPublicID, sessionUUID])

    const updateBuildingDetailsFunc = useCallback(async (dto) => {
        const res = await CPRisksService.updateBuildingDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, dto, authHeader);
        const splitedDTO = splitBuildingDetailsDTO(res.building)
        return [splitedDTO, res.buildingClauses]
    }, [authHeader, buildingPublicID, jobID, locationPublicID, sessionUUID])

    const updateBuildingDescriptionFunction = useCallback(async (dto) => {
        const res = await CPRisksService.updateBuildingDescription(jobID, sessionUUID, locationPublicID, buildingPublicID, dto, authHeader);
        const splitedDTO = splitBuildingDetailsDTO(res)
        return splitedDTO
    }, [authHeader, buildingPublicID, jobID, locationPublicID, sessionUUID])


    const addFireProtectionFunc = useCallback(async(DTO) => {
        const res = await CPRisksService.addFireProtectionSystem(jobID, sessionUUID, locationPublicID, buildingPublicID, DTO, authHeader);
        const sortTableList = res.sort((a,b) => {return Number(a.publicID.substring(3))-Number(b.publicID.substring(3))} )
        return sortTableList
    }, [authHeader, buildingPublicID, jobID, locationPublicID, sessionUUID])
    

    
    const removeFireProtectionFunc = useCallback(async(indexNumber) => {
        const res = await CPRisksService.removeFireProtectionSystem(jobID, sessionUUID, locationPublicID, buildingPublicID, indexNumber, authHeader);
        return res
    }, [authHeader, buildingPublicID, jobID, locationPublicID, sessionUUID])
    


    const getBuildingClausesFunc = useCallback(async () => {
        const res = await CPRisksService.getBuildingClauses(jobID, sessionUUID, locationPublicID, buildingPublicID, authHeader)
        return res
    }, [authHeader, buildingPublicID, jobID, locationPublicID, sessionUUID])

    const updateBuildingClausesFunc = async (dto) => {
        updateValidationIssues([]);
        const { building, buildingClauses: newBuildingClauses, errorsAndWarnings } = await CPRisksService.updateBuildingClauses(jobID, sessionUUID, locationPublicID, buildingPublicID, dto, authHeader)
        if (!_.isEmpty(errorsAndWarnings)) {
            const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
            updateValidationIssues(newValidationIssues);
        }
        const splitedDTO = splitBuildingDetailsDTO(building)
        const buildingDetailsVM = viewModelService.create(splitedDTO[0], 'pc', dtoName);
        const additionalBuildingDetailsVM = viewModelService.create(splitedDTO[1], 'pc', dtoName);
        // _.set(riskItem, 'value', res.occupancyDisplayables);
        updateDetailsVM(buildingDetailsVM)
        updateAdditionalDetailsVM(additionalBuildingDetailsVM)
        return newBuildingClauses
    }

    const searchAddressBookFunc = async (searchCriteria) => {
        const res = await withLoadingMask(() => CPRisksService.searchAddressBook(jobID, sessionUUID, locationPublicID, buildingPublicID, searchCriteria, authHeader))
        return res
    }

    const addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc = async (searchCriteria, contactResultId) => {
        const newMortgageeDetails = await withLoadingMask(() => CPRisksService.addMortgageeByAddressBookSearchResultWhileUpdate(
            jobID, sessionUUID, locationPublicID, buildingPublicID, searchCriteria, contactResultId, mortgageeDetails, authHeader))
        updateMortGageeDetails(newMortgageeDetails)
    }  

    const initFieldData = useCallback(async () => {
        setLoadingMask(true)
        // const buidlingDetailsDto = await getBuildingDetailsFunc()
        // const newBuildingClauses = await getLocationClausesFunc()
        const [buidlingDetailsDtos, newBuildingClauses] = await Promise.all([
            getBuildingDetailsFunc(),
            getBuildingClausesFunc()
        ])
        
        const buildingDetailsVM = viewModelService.create(buidlingDetailsDtos[0], 'pc', dtoName);
        const additionalBuildingDetailsVM = viewModelService.create(buidlingDetailsDtos[1], 'pc', dtoName);
        setBuildingClauses(newBuildingClauses)
        setLoadingMask(false)
        updateDetailsVM(buildingDetailsVM)
        updateAdditionalDetailsVM(additionalBuildingDetailsVM)
        
    // !!! Never add setLoadingMask into dependency, which always different after render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBuildingClausesFunc, viewModelService])


    const updateService = async(serviceData, type) => {

        setLoadingMask(true);
        const latestCurrentVMDisplayables = _.get(serviceData, 'buildingDisplayables', []);
        let allDisplayables = latestCurrentVMDisplayables;
        const detailsDisplayables = _.get(detailsVM, 'value.buildingDisplayables', []);
        const additionalDetailsDisplayables = _.get(additionalDetailsVM, 'value.buildingDisplayables', []);
        if (type === 'details') {
            allDisplayables = latestCurrentVMDisplayables.concat(additionalDetailsDisplayables);
        } 
        if (type === 'additional') {
            allDisplayables = latestCurrentVMDisplayables.concat(detailsDisplayables);
        }
        
        const [needsRefresh, notNeedRefresh] = _.partition(allDisplayables?.filter(elt => elt.updated), 'needsRefresh');
        const orderedDisplayables = notNeedRefresh.concat(needsRefresh);
        Object.assign(serviceData, {'changedBuildingDisplayables': orderedDisplayables});
        const [updatedBuildlingDetailsDtos, newBuildingClauses] = await updateBuildingDetailsFunc(serviceData);
        const buildingDetailsVM = viewModelService.create(updatedBuildlingDetailsDtos[0], 'pc', dtoName);
        const additionalBuildingDetailsVM = viewModelService.create(updatedBuildlingDetailsDtos[1], 'pc', dtoName);
        // _.set(riskItem, 'value', res.occupancyDisplayables);
        updateDetailsVM(buildingDetailsVM)
        setBuildingClauses(newBuildingClauses)
        updateAdditionalDetailsVM(additionalBuildingDetailsVM)
        setLoadingMask(false);
    };

    const updateBuildingDescriptionService = async(serviceData) => {
        setLoadingMask(true)
        const updatedBuildlingDetailsDtos = await updateBuildingDescriptionFunction(serviceData)
        const buildingDetailsVM = viewModelService.create(updatedBuildlingDetailsDtos[0], 'pc', dtoName);
        const additionalBuildingDetailsVM = viewModelService.create(updatedBuildlingDetailsDtos[1], 'pc', dtoName);
        // _.set(riskItem, 'value', res.occupancyDisplayables);
        updateDetailsVM(buildingDetailsVM)
        updateAdditionalDetailsVM(additionalBuildingDetailsVM)
        setLoadingMask(false);
    };

    const addFireProtection = async(DTO) => {
        setLoadingMask(true)
        const newFireProtectionTableDTO = await addFireProtectionFunc(DTO)
        const newVM = viewModelService.clone(additionalDetailsVM);
        _.set(newVM.value, 'additionalDetails.fireProtectionSystemData', newFireProtectionTableDTO)
        updateAdditionalDetailsVM(newVM)
        setLoadingMask(false)
    }




    const writeBuildingDescriptionValue = useCallback(
        async(value, path) => {
            const newVM = _.clone(detailsVM);
            if (path === 'buildingDescription') {
                _.set(newVM.value, 'buildingDescription', value)
            }
            updateDetailsVM(newVM)
        },
        [detailsVM]
    );

    const onBuildingDescriptionBlur = () => {
        updateBuildingDescriptionService(detailsVM.value)
    };

  
    const writeDetailsValue = (fieldItem, path, fieldModel = {}) => {
        
        const newVm = viewModelService.clone(detailsVM);
        _.set(newVm.value, path, fieldItem);
        updateDetailsVM(newVm);
        if(fieldModel.triggerFunc === 'onValueChange' && fieldItem.needsRefresh) {
            // _.set(currentRowVM.value, path, fieldItem); 
            updateService(newVm.value, 'details');
        }

    }

    const onBlur = () => {
        updateService(detailsVM.value, 'details')
    };

    const onAdditionalDetailsDisplayableBlur = (value, fieldItem) => {
        if (fieldItem.needsRefresh) {
            updateService(additionalDetailsVM.value, 'additional')
        }
    };

    const writeAdditionalDetailsValue = (fieldItem, path, fieldModel = {}) => {
        if(path?.toString().startsWith('additionalDetails')){
            const newVM = _.clone(additionalDetailsVM)
            _.set(newVM.value, path, fieldItem);
            updateAdditionalDetailsVM(newVM)
        }
        else{
            const newVm = viewModelService.clone(additionalDetailsVM);
            _.set(newVm.value, path, fieldItem);
            updateAdditionalDetailsVM(newVm);
            if(fieldModel.triggerFunc === 'onValueChange' && fieldItem.needsRefresh) {
                updateService(newVm.value, 'additional');
            }
        }
       
    }

    
    const writeFireProtectionValue = (newValue, path) => {
        const newVm = viewModelService.clone(additionalDetailsVM);
        _.set(newVm.value, path, newValue);
        updateAdditionalDetailsVM(newVm);

    }

    const getGenerateNewRiskTreeRowFunc = async () => {
        const closeRequestDTO = {
            ...additionalDetailsVM.value,
            mortgageeDetails,
        }
        const updatedDetailsDisplayables = _.get(detailsVM, 'value.buildingDisplayables', [])?.filter(elt => elt.updated);
        const updatedAdditionalDetailsDisplayables = _.get(additionalDetailsVM, 'value.buildingDisplayables', [])?.filter(elt => elt.updated);
        const allUpdatedDisplayables = updatedDetailsDisplayables.concat(updatedAdditionalDetailsDisplayables);
        if (!_.isEmpty(allUpdatedDisplayables)) {
            const [updatedBuildlingDetailsDtos, newBuildingClauses] = await updateBuildingDetailsFunc( {'changedBuildingDisplayables': allUpdatedDisplayables});
            const buildingDetailsVM = viewModelService.create(updatedBuildlingDetailsDtos[0], 'pc', dtoName);
            const additionalBuildingDetailsVM = viewModelService.create(updatedBuildlingDetailsDtos[1], 'pc', dtoName);
            updateDetailsVM(buildingDetailsVM);
            setBuildingClauses(newBuildingClauses);
            updateAdditionalDetailsVM(additionalBuildingDetailsVM);
        }
        const buildingSummary = await withLoadingMask(() => CPRetrieveRiskItemSummaryService.retrieveBuildingSummary(
            jobID, sessionUUID, locationPublicID, buildingPublicID, closeRequestDTO, authHeader
        ))
        const {coverableColumnBasicInfo, errorsAndWarnings} = buildingSummary;
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);
        return {
            'generateNewTiskTreeRowFunc': (oldRiskTreeRows) => {
                const newRiskTreeRows = _.clone(oldRiskTreeRows)
                const locationIndex = oldRiskTreeRows.findIndex(location => location.publicID === locationPublicID)
                const buildings = _.get(newRiskTreeRows, `[${locationIndex}].buildingTreeRows`)
                const buildingIndex = buildings.findIndex(building => building.publicID === buildingPublicID)
                _.set(newRiskTreeRows, `[${locationIndex}].buildingTreeRows[${buildingIndex}].coverableColumnBasicInfo`, coverableColumnBasicInfo)

                return newRiskTreeRows
            },
            'newValidationIssues': newValidationIssues
        }

    }

    const writeSearchedDetailsValue = async(fieldItem, seletedRow) => {
        const { propertyName } = fieldItem;
        setLoadingMask(true)
        const updatedBuildlingDetailsDtos = await CPRetrieveRiskItemSummaryService.getUpdatedBuildingDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, propertyName, seletedRow, authHeader);
        const splitedDTO = splitBuildingDetailsDTO(updatedBuildlingDetailsDtos)
        const buildingDetailsVM = viewModelService.create(splitedDTO[0], 'pc', dtoName);
        updateDetailsVM(buildingDetailsVM);
        setLoadingMask(false);
    }

    const handleSearch = async(fieldItem = {}) => {
        const { propertyName } = fieldItem;
        const ratingTypeValue = fieldModels.find(elt => elt.propertyName === 'RatingType')?.rangeValue;      
        const tableData = await  withLoadingMask(() => CPRetrieveRiskItemSummaryService.fetchBuildingClassCodeTable(jobID, sessionUUID, locationPublicID, buildingPublicID, propertyName, authHeader));
        const isClassDescriptionSearch = propertyName === "ClassificationDescription";
        showSearchModal(tableData, ratingTypeValue, isClassDescriptionSearch)
            .then((seletedRow) => {
                const ClassCodeDisplayable = fieldModels.find(elt => elt.propertyName === propertyName);
                writeSearchedDetailsValue(ClassCodeDisplayable, seletedRow);
            }).catch(() => {
                _.noop();
            })
    };

    const addNewContact = (newContactType) => {
        const primaryAddress = _.get(submissionVM, 'value.baseData.accountHolder.primaryAddress')
        const contactVM = viewModelService.create(
            {
                contactCode: newContactType,
                primaryAddress,
                contactRoleType: 'PolicyContactRoles'
            },
            'pc',
            'wni.edge.capabilities.policycommon.accountcontact.dto.WniAccountContactDTO'
        )
        const componentProps = {
            contactVM,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            size: 'lg',
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            addContactService: async (requestData) => {
                return CPRisksService.addNewContactAsMortgageeAdditionalInterestWhileUpdate(
                    jobID,
                    sessionUUID,
                    locationPublicID,
                    buildingPublicID,
                    requestData.contact,
                    mortgageeDetails,
                    authHeader
                )
            }
        };
        return modalApi.showModal(
            <AddContactPopup {...componentProps} />
        ).then((res) => {
            updateMortGageeDetails(res)
        }).catch(() => {
            _.noop();
        });;
    }

    const handleClose = async() => {
        if (isReadOnly) {
            handleCloseRisk((oldTreeRows) => oldTreeRows)
            return
        }
        if (!isComponentValid || !_.get(additionalDetailsVM, 'aspects.valid') || !_.get(additionalDetailsVM, 'aspects.subtreeValid')) {
            updateShowErrors(true)
            return
        }
        const {generateNewTiskTreeRowFunc, newValidationIssues} = await getGenerateNewRiskTreeRowFunc()
        if (!_.isEmpty(newValidationIssues)) {
            updateShowErrors(true)
            return
        }
        handleCloseRisk((oldTreeRows) => generateNewTiskTreeRowFunc(oldTreeRows))
    }

    useEffect(() => {
        initFieldData()
    }, [initFieldData])
    
    const overrideProps = {
        fieldSetModel: {
            vm: detailsVM,
            dataPath: 'buildingDisplayables',
            // submissionVM,
            onValueChange: writeDetailsValue,
            onSearch: handleSearch,
            onValidate,
            onBlur,
            showErrors,
            isReadOnly,
            // filterSizeClassMapsInChange
        },
        riskItemDetailsCard: {
            title: 'Building Details'
        },
        locationInput: {
            visible: true,
            value: locationDescription
        },
        buildingDescriotionInput: {
            visible: true,
            required: true,
            value: _.get(detailsVM.value, 'buildingDescription'),
            readOnly: isReadOnly,
            onBlur: onBuildingDescriptionBlur,
        }
    }
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            fieldsetmap: DetailsFieldMap
        }
    };
    return (
        <>
            <ValidationIssuesComponent validationIssues={validationIssues} scrollToIssues/>
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={writeBuildingDescriptionValue}
                model={detailsVM}
                // resolveValue={readValue}
                // classNameMap={resolvers.resolveClassNameMap}
                // callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            <BuildingClauses
                riskItem={riskItem}
                serviceProps={serviceProps}
                submissionVM={submissionVM}
                updateSubmissionVM={updateSubmissionVM}
                updateClauseFunc={updateBuildingClausesFunc}
                riskClauses={buildingClauses}
                setRiskClauses={setBuildingClauses}
                showErrors={showErrors}
                isEditable={!isReadOnly}
                onValidate={onValidate}
                mortgageeDetails={mortgageeDetails}
                updateMortGageeDetails={updateMortGageeDetails}
                searchAddressBookFunc={searchAddressBookFunc}
                addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc={addNewSelectContactInfoMortgageeWhileUpdateMortgageeFunc}
                addNewContact={addNewContact}
            />
            {
                _.isEmpty(additionalDetailsVM)?null:
                <AdditionalBuildingDetails
                    vm={additionalDetailsVM}
                    updateVM={updateAdditionalDetailsVM}
                    writeAdditionalDetailsValue={writeAdditionalDetailsValue} // update AdditionalDetails - Entity
                    isReadOnly={isReadOnly}
                    onBlur={onAdditionalDetailsDisplayableBlur}
                    addFireProtection={addFireProtection}
                    fireProtectionSystemDataVM= {fireProtectionSystemDataVM}
                    writeFireProtectionValue={writeFireProtectionValue}
                    removeFireProtectionFunc={removeFireProtectionFunc}
                    additionalDetailsVM={additionalDetailsVM}
                    updateAdditionalDetailsVM={updateAdditionalDetailsVM}
                />
            }

            <Flex justifyContent="right">
                <Button onClick={handleClose}>
                    {translator(messages.Close)}
                </Button>
            </Flex>
            
        </>
    );
}

export default Building